import React from "react"
import { Layout, SEO } from "../components/Layout/"
import {
  Hero,
  Office,
  Freelancer,
  Wellness,
  // OfficeSearch,
} from "../components/Home"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <Hero data={data} />
    {/* <OfficeSearch /> */}
    <Office />
    <Freelancer />
    {/* <Wellness /> */}
  </Layout>
)

export default IndexPage
