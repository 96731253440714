import React from "react"
import { FormattedMessage, IntlContextConsumer } from "gatsby-plugin-intl"
import officeIllustration from "./office_illustration.svg"
import "./Office.scss"

const Office = () => {
  const { GATSBY_APP_URL } = process.env
  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <section id="office">
          <div className="container pt-sm-5">
            <div className="row justify-content-lg-between">
              <div className="col-12 col-sm-7 col-lg-6 text-center text-sm-left">
                <FormattedMessage id="office.heading">
                  {txt => <h2 className="py-2">{txt}</h2>}
                </FormattedMessage>

                <FormattedMessage id="office.description">
                  {txt => <p>{txt}</p>}
                </FormattedMessage>

                <ul className="py-4 py-lg-5 features text-left">
                  <FormattedMessage id="office.list.trust" tagName="li" />
                  <FormattedMessage id="office.list.track" tagName="li" />
                  <FormattedMessage
                    id="office.list.communication"
                    tagName="li"
                  />
                </ul>

                <FormattedMessage id="buttons.office">
                  {txt => (
                    <a
                      className="btn btn-primary"
                      rel="noopener noreferrer"
                      target="_blank"
                      href={`https://app.traduc.ro/auth/register/office?lng=${currentLocale}`}
                    >
                      {txt}
                    </a>
                  )}
                </FormattedMessage>
              </div>
              <div className="col-12 col-sm-5 pt-5 pt-sm-0 mx-0 text-center">
                <img
                  className="w-sm-100 mx-auto"
                  src={officeIllustration}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </IntlContextConsumer>
  )
}

export default Office
