import React from "react"
import { IntlContextConsumer, Link, FormattedMessage } from "gatsby-plugin-intl"
import heroIllustration from "./hero_illustration.svg"
import "./Hero.scss"

const Hero = () => {
  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <section id="hero">
          <div className="container text-white pt-lg-4">
            <div className="row pt-md-5">
              <div className="col-12 col-sm-7 col-lg-6 text-center text-sm-left">
                <FormattedMessage id="hero.heading">
                  {txt => <h1 className="pt-4">{txt}</h1>}
                </FormattedMessage>

                <FormattedMessage id="hero.description">
                  {txt => <p className="py-4 py-sm-3 py-lg-4 mb-0">{txt}</p>}
                </FormattedMessage>

                <FormattedMessage id="buttons.office">
                  {txt => (
                    <Link
                      to="/#office"
                      className="btn btn-block btn-secondary d-md-inline-block w-sm-auto mr-md-3 mt-md-2"
                    >
                      {txt}
                    </Link>
                  )}
                </FormattedMessage>
                <FormattedMessage id="buttons.freelancer">
                  {txt => (
                    <Link
                      to="/#freelancer"
                      className="btn btn-block btn-light d-md-inline-block w-sm-auto mt-3 mt-md-2 mr-md-3"
                    >
                      {txt}
                    </Link>
                  )}
                </FormattedMessage>
                <a
                  className="btn btn-block btn-green d-inline-block d-md-none w-sm-auto mt-3 mt-md-2"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`https://app.traduc.ro/auth/login?lng=${currentLocale}`}
                >
                  <FormattedMessage id="buttons.login" />
                </a>
              </div>
              <div
                className="col-12 col-sm-5 position-sm-absolute pt-5 pb-3 px-sm-0 py-sm-4 pt-lg-0 right-0"
                id="hero-img"
              >
                <img className="w-100" src={heroIllustration} alt="" />
              </div>
            </div>
          </div>
        </section>
      )}
    </IntlContextConsumer>
  )
}

export default Hero
